import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class UtilsService {
    /*
    * SO-11720 - Cancellation flow shop picker
    * navFromCancellationScreenEvent$ - will check if /map opened from cancelled feedback -> shopList (UI - select on the map button)
    * navFromDashboardNPSScreenEvent$ - will check if /map opened from dashboard NPS -> shopList (UI - select on the map button)
    * */
    navFromCancellationScreenEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    navFromDashboardNPSScreenEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    randomizeEvent$ = new BehaviorSubject(NaN);

    // https://sonectapp.atlassian.net/browse/SO-12320
    layoutEvent$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    layoutValue$: Observable<string> = this.layoutEvent$.asObservable();

    months: string[] = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    sorter: { [key: string]: number } = {
        // "sunday": 0, // << if sunday is first day of week
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7
    };
    weekdays: string[] = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
    ];

    private static getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getMonths(): string[] {
        return this.months;
    }

    setRandomValue(min, max) {
        const value = UtilsService.getRandomIntInclusive(min, max);
        this.randomizeEvent$.next(value);
    }

    getRandomValue(): number {
        return this.randomizeEvent$.getValue();
    }

    shuffleReasons<T>(reasons: T[]): void {
        for (let i = reasons.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [reasons[i], reasons[j]] = [reasons[j], reasons[i]];
        }
    }

    rangeRandomCalcValue(max: number): number {
        return Math.floor(Math.random() * max);
    }

    buildWorkingPeriod(breaks, workingPeriod, openTime, closeTime): { start: string, end: string }[] {
        breaks.forEach((v, index, array): void => {
            if (index === 0) {
                workingPeriod = [{ start: openTime, end: array[index].start }]
            } else {
                workingPeriod = [...workingPeriod, { start: array[index - 1].end, end: array[index].start }];
            }
        });

        return [...workingPeriod, { start: breaks[breaks.length - 1].end, end: closeTime }];  // last item
    }
}
