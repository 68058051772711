export enum ShopStatuses {
    open = "open",
    closed = "closed",
    unavailable = "unavailable",
    not_available = "not_available" // name for incorrect data
}

export enum ShopStatusName {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}
