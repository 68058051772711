/**
 * Created by Arindam Bajpayee on 25-04-2017.
 */
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, FactoryProvider, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgProgressModule } from "@ngx-progressbar/core";

import { AppRoutingModule } from "./app-routing.module";
import { LandscapeModeModule } from "./pages/protected/landscape-mode/landscape-mode.module";
import { MainButtonModule } from "./pages/shared/main-button/main-button.module";
import { PipesModule } from "./pipes/pipes.module";

import { AppComponent } from "./app.component";
import { AuthenticationService, LocationService, ShopSearchService, UserLanguagesService } from "./services";
import { ThemeService } from "./services/theme.service";
import { MapService } from "./pages/protected/map/services/map.service";
import { AuthInterceptor } from "./interceptors/auth-interceptor";
import { WrapperColorDirective } from "./directives/wrapper-color.directive";
import { THEME_NAME } from "./constants/theme.config";
import { LOCAL_STORAGE_KEYS } from "./constants/localStorageKeys";


function loadConfigFactory(themeService: ThemeService) {
    const theme = localStorage.getItem(LOCAL_STORAGE_KEYS.theme);
    // https://sonectapp.atlassian.net/browse/SO-13323
    // The theme settings in the web app are stored in the browser.
    // By default, if there are no settings, then the theme is set to black. Taking into account the new requirements, it will be replaced by white (by default)
    // If the user clears the browser cache (local Storage), then the next time user open it, he will get a white theme.
    return () => themeService.setTheme(!!theme ? theme : THEME_NAME.WHITE);
}

function loadMapFactory(mapService: MapService) {
    return () => mapService.loadGoogleMaps();
}

export const loadConfigProvider: FactoryProvider = {
    provide: APP_INITIALIZER,
    useFactory: loadConfigFactory,
    deps: [ThemeService],
    multi: true
};

export const loadMapProvider: FactoryProvider = {
    provide: APP_INITIALIZER,
    useFactory: loadMapFactory,
    deps: [MapService],
    multi: true
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        WrapperColorDirective,
    ],
    imports: [
        LandscapeModeModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientJsonpModule,
        HttpClientModule,
        NgProgressModule,
        AppRoutingModule,
        MainButtonModule,
        PipesModule.forRoot()
    ],
    providers: [
        AuthenticationService,
        LocationService,
        ShopSearchService,
        UserLanguagesService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        loadConfigProvider,
        loadMapProvider
    ]
})
export class AppModule {
}
