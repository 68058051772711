import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TranslocoRootModule } from "../../../transloco/transloco-root.module";

import { MainButtonComponent } from "./main-button.component";

@NgModule({
    imports: [
        CommonModule,
        TranslocoRootModule,
    ],
    exports: [MainButtonComponent],
    declarations: [MainButtonComponent]
})
export class MainButtonModule {
}
