import { Injectable } from "@angular/core";

import { Fee } from "../interfaces/fee";
import { SESSION_STORAGE_KEYS } from "src/app/constants/sessionStorageKeys";

@Injectable({
    providedIn: "root"
})

export class FeesCalculatingService {
    readonly FEE_TYPES = {
        percentage: "percentage",
        fixed: "fixed",
    };

    get userFeesList(): Fee[] {
        const { pricing } = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.configuration));

        return pricing?.fees ?? [];
    }

    calculateFee(amount): number {
        const feesList = this.userFeesList;
        const feeForSelectedAmount = feesList.find(fee => fee.min <= amount && fee.max >= amount);

        return feeForSelectedAmount?.fixed;
    }

}
