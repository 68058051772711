import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthenticationService } from "../services";
import { SESSION_STORAGE_KEYS } from "src/app/constants/sessionStorageKeys";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.accessToken);

        const findFreshdesk = /\bfreshdesk\b/;
        // exclude translations files from assets
        if (accessToken && accessToken.length && request.url && findFreshdesk.test(request.url) === false && !request.url.includes('/assets/i18n/')) {
            request = request.clone(this.authService.getHeaders());
        }

        return next.handle(request)
            .pipe(
                tap({
                    next: () => {},
                    error: (err) => {
                        if (err instanceof HttpErrorResponse) {
                            if ((err.status !== HttpStatusCode.Unauthorized)) {
                                return;
                            }

                            if (this.router.url !== "/map") {
                                this.router.navigate(["expired"]);
                            }
                        }
                    }
                })
            );
    }
}
