/**
 * Created by Arindam Bajpayee on 01-05-2017.
 */
import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { AuthenticationService } from "../services";
import { environment } from "../../environments/environment";

export const authGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean => {
    const authenticationService = inject(AuthenticationService);
    const router = inject(Router);

    if (environment.name === "DEV" && environment.disableAuthGuard) {
        return true;
    }

    if (authenticationService.getAccessToken() || state.url === "/map") {
        return true;
    }

    return router.createUrlTree(["start"]);
}
