import { NgModule } from "@angular/core";

import { DistancePipe } from "./distance.pipe";
import { CapitalizeCasePipe } from "./capitalizeCase.pipe";
import { FormatDatePipe } from "./format-date.pipe";
import { SortByDayPipe } from "./sort-by-day.pipe";
import { CardWorkingHoursPipe } from './cardWorkingHours.pipe';

@NgModule({
    declarations: [DistancePipe, CapitalizeCasePipe, FormatDatePipe, SortByDayPipe, CardWorkingHoursPipe],
    imports: [],
    exports: [DistancePipe, CapitalizeCasePipe, FormatDatePipe, SortByDayPipe, CardWorkingHoursPipe]
})
export class PipesModule {
    static forRoot() {
        return {
            ngModule: PipesModule,
            providers: [],
        };
    }
}
