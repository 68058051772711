export const LOCAL_STORAGE_KEYS = {
    partnerName: "partnerName",
    referrer: "referrer",
    rulesFirst: "rulesFirst", // auth
    rulesSecond: "rulesSecond", // confirm transaction
    rulesThird: "rulesThird", // cancel transaction
    sessionCount: "session_count",
    successfulTransaction: "withdrawal_confirmed",
    cancelledTransaction: "withdrawal_cancelled",
    theme: "theme",
    overlay: "overlay",
    rangeRandomThemeValue: "rangeRandomThemeValue", // https://sonectapp.atlassian.net/browse/SO-11869
    recentSearch: "recentSearch",
    receivedTransactions: "receivedTransactions",
    promotion: "promotion"
};
