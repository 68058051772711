import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { THEME_PALETTE } from "../constants/theme.config";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";

@Injectable({
    providedIn: "root"
})
export class ThemeService {

    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    setTheme(name: string): void {
        const options = THEME_PALETTE[name];
        localStorage.setItem(LOCAL_STORAGE_KEYS.theme, name);
        Object.keys(options).forEach((key: string) => {
            this.document.documentElement.style.setProperty(`--${key}`, options[key]);
        });
    }

    // setTheme(value: number, name: string): void {
    //     // https://sonectapp.atlassian.net/browse/SO-11869
    //     const options = THEME_PALETTE[name];
    //     localStorage.setItem(LOCAL_STORAGE_KEYS.theme, name);
    //     Object.keys(options).forEach((key: string) => {
    //         this.document.documentElement.style.setProperty(`--${key}`, options[key]);
    //     });
    // }

    // rangeRandomCalcValue(max: number): number {
    //     // https://sonectapp.atlassian.net/browse/SO-11869
    //     // random value [0, 1]
    //     // should work until June 1st 2022
    //     return Math.floor(Math.random() * max);
    // }
}
