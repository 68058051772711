import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "distance"
})
export class DistancePipe implements PipeTransform {
    transform(distance: number): string {
        if (distance > 999) {
            return (distance / 1000).toFixed() + "km";
        }

        return distance >= 100 ? distance + "m" : "<100m";
    }
}
