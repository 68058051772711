/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { authGuard } from "./guards/authentication.guard";
import { loginGuard } from "./guards/login.guard";
import { codeGuard } from "./guards/code.guard";
import { promocodeGuard } from "./guards/promocode.guard";

const routes: Routes = [
    {
        path: "start",
        loadChildren: () => import("./pages/public/start/start.module").then(m => m.StartModule)
    },
    {
        path: "expired",
        loadChildren: () => import("./pages/public/session-expired/session-expired.module").then(m => m.SessionExpiredModule)
    },
    {
        path: "login",
        loadChildren: () => import("./pages/protected/login/login.module").then(m => m.LoginModule),
        canActivate: [loginGuard],
    },
    {
        path: "",
        canActivateChild: [authGuard],
        children: [
            {
                path: "dashboard",
                loadChildren: () => import("./pages/protected/dashboard/dashboard.module").then(m => m.DashboardModule),
                data: { animation: "dashboard" }
            },
            {
                path: "map",
                loadChildren: () => import("./pages/protected/map/map.module").then(m => m.MapModule),
                data: { animation: "map" }
            },
            {
                path: "profile",
                loadChildren: () => import("./pages/protected/profile/profile.module").then(m => m.ProfileModule),
                data: { animation: "profile" }
            },
            {
                path: "profile/contact",
                loadChildren: () => import("./pages/protected/contact/contact.module").then(m => m.ContactModule),
                data: { animation: "contact" }
            },
            {
                path: "profile/about",
                loadChildren: () => import("./pages/protected/about-us/about-us.module").then(m => m.AboutUsModule),
                data: { animation: "about" }
            },
            {
                path: "monitor",
                loadChildren: () => import("./pages/protected/transaction-list/transaction-list.module").then(m => m.TransactionListModule),
                data: { animation: "transaction-list" }
            },
            {
                path: "transaction/:id",
                loadChildren: () => import("./pages/protected/transaction-details/transaction-details.module").then(m => m.TransactionDetailsModule),
                data: { animation: "transaction-details" }
            },
            {
                path: "tutorial",
                loadChildren: () => import("./pages/protected/tutorial/tutorial.module").then(m => m.TutorialModule),
                data: { animation: "tutorial" }
            },
            {
                path: "withdraw",
                loadChildren: () => import("./pages/protected/withdraw/withdraw.module").then(m => m.WithdrawModule),
                data: { animation: "withdraw" }
            },
            {
                path: "promocode",
                loadChildren: () => import("./pages/protected/promocode/promocode.module").then(m => m.PromocodeModule),
                canActivate: [promocodeGuard],
                data: { animation: "promocode" }
            },
            {
                path: "top-up",
                loadChildren: () => import("./pages/protected/top-up/top-up.module").then(m => m.TopUpModule),
                data: { animation: "top-up" }
            },
            { path: "", redirectTo: "/dashboard", pathMatch: "full" },
        ]
    },
    {
        path: "code",
        loadChildren: () => import("./pages/public/code/code.module").then(m => m.CodeModule),
        canActivate: [codeGuard]
    },
    { path: "", redirectTo: "/start", pathMatch: "full" },
    { path: "**", redirectTo: "/dashboard", pathMatch: "full" }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
