import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-main-button",
    templateUrl: "./main-button.component.html",
    styleUrls: ["./main-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainButtonComponent {
    @Output() clickEvent = new EventEmitter<void>();
    // types could be: main, secondary, info, tertiary
    // each type used as css class
    @Input() buttonType = "main";
    @Input() messageAlias = "authorized.continue_next";
    @Input() disabled: boolean;
    @Input() aliasParams: {};
    @Input() customValue: any;

}
