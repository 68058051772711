import {
    animate,
    animateChild,
    animation,
    group,
    query,
    style,
    transition,
    trigger,
} from "@angular/animations";

// slide from right to left
function slideX(direction: string) {
    return [
        style({ position: "relative" }),
        query(":enter, :leave", [
            style({
                position: "absolute",
                top: 0,
                [direction]: 0,
                width: "100%",
            }),
        ]),
        query(":enter", [style({ [direction]: "-100%" })]),
        query(":leave", animateChild()),
        group([
            query(":leave", [
                animate("300ms ease-out", style({ [direction]: "100%" })),
            ]),
            query(":enter", [
                animate("300ms ease-out", style({ [direction]: "0%" })),
            ]),
        ]),
        query(":enter", animateChild()),
    ];
}

// in case we ever use the Y transitions again
// slide from right to left
// function slideY(direction: string) {
//     return [
//         style({position: "relative"}),
//         query(":enter, :leave", [
//             style({
//                 position: "absolute",
//                 [direction]: 0,
//                 left: 0,
//                 width: "100%",
//             }),
//         ]),
//         query(":enter", [style({[direction]: "-100%"})]),
//         query(":leave", animateChild()),
//         group([
//             query(":leave", [
//                 animate("300ms ease-out", style({[direction]: "100%"})),
//             ]),
//             query(":enter", [animate("300ms ease-out", style({[direction]: "0"}))]),
//         ]),
//         query(":enter", animateChild()),
//     ];
// }

export const slideInAnimations = trigger("routeAnimations", [
    // x-axis slide animations
    transition("dashboard => map", slideX("right")),
    transition("dashboard => profile", slideX("right")),
    transition("dashboard => transaction-details", slideX("right")),
    transition("dashboard => tutorial", slideX("right")),
    transition("dashboard => withdraw", slideX("right")),
    transition("map => withdraw", slideX("right")),
    transition("profile => contact", slideX("right")),
    transition("profile => about", slideX("right")),
    transition("profile => transaction-list", slideX("right")),
    transition("transaction-list => transaction-details", slideX("right")),
    transition("code => code-enter", slideX("right")),
    transition("transaction-details => contact", slideX("right")),
    transition("dashboard => promocode", slideX("right")),
    transition("dashboard => top-up", slideX("right")),

    // x-axis slide animations, reverse
    transition("contact => profile", slideX("left")),
    transition("about => profile", slideX("left")),
    transition("map => dashboard", slideX("left")),
    transition("profile => dashboard", slideX("left")),
    transition("transaction-list => profile", slideX("left")),
    transition("transaction-details => dashboard", slideX("left")),
    transition("transaction-details => transaction-list", slideX("left")),
    transition("tutorial => dashboard", slideX("left")),
    transition("withdraw => dashboard", slideX("left")),
    transition("withdraw => map", slideX("left")),
    transition("code-enter => code", slideX("left")),
    transition("code-withdraw => code-enter", slideX("left")),
    transition("tutorial => withdraw", slideX("left")),
    transition("promocode => dashboard", slideX("left")),
    transition("top-up => dashboard", slideX("left")),
    // // in case we ever use the Y transitions again
    // // y-axis slide animations
    // transition("dashboard => withdraw", slideY("bottom")),
    // transition("dashboard => map", slideY("bottom")),

    // // y-axis slide animations, reverse
    // transition("withdraw => dashboard", slideY("top")),
    // transition("map => dashboard", slideY("top")),
]);
