import { inject, Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslocoService } from "@ngneat/transloco";

import { LocationService } from "./location.service";
import { UtilsService } from "./utils.service";
import { ShopStatuses, ShopStatusName } from "../constants/shopStatuses";
import { Atm } from "../models";
import { ShopStatus } from "../interfaces";
import { SelectShopButton } from "../pages/protected/transaction-details/interfaces/buttons.interface";
import { shopListNoShopsIcon, shopListSelectOnMapIcon } from "../constants/svgs";

@Injectable({
    providedIn: "root",
})
export class ShopDataHelperService {
    private locationService: LocationService = inject(LocationService);
    private translocoService: TranslocoService = inject(TranslocoService);
    private utilsService: UtilsService = inject(UtilsService);
    private sanitized: DomSanitizer = inject(DomSanitizer);

    stringToMinutes(timeString: string): number {
        const splitString: string[] = timeString.split(":");
        const totalMinutes: number = (splitString[0] === "00") ? 24 * 60 : parseInt(splitString[0], 10) * 60 + parseInt(splitString[1], 10);

        return totalMinutes;
    }

    getTimeInMinutes(currentTimeStamp: Date): number {
        // returns current minutes
        return currentTimeStamp.getHours() * 60 + currentTimeStamp.getMinutes();
    }

    shopAvailability(atm: Atm): ShopStatus {
        if (atm.status.name === ShopStatusName.INACTIVE && atm.status.reason === "SHOP_CLOSED_NETWORK_ERROR") {
            return {
                label: this.translocoService.translate("authorized.currently_not_available"),
                status: ShopStatuses.unavailable
            };
        }

        if (atm.status.name === ShopStatusName.INACTIVE) {
            return {
                label: this.translocoService.translate("authorized.closed"),
                status: ShopStatuses.closed
            };
        }

        const openingHours = atm.openingHours;
        const openNowText = this.translocoService.translate("authorized.open_now");
        const currentTimeStamp = new Date(Date.now());
        const currentDayNum = currentTimeStamp.getDay();
        const currentDay = this.utilsService.weekdays[currentDayNum];
        // compare w/ open & close of atm
        const currentTimeInMinutes = this.getTimeInMinutes(currentTimeStamp);
        const { open: openTime, close: closeTime, breaks } = openingHours[currentDay];
        const openMinutes = this.stringToMinutes(openTime);
        const closeMinutes = this.stringToMinutes(closeTime);
        let workingPeriod = [];
        if (!!breaks && breaks.length) {
            // if atm has breaks
            workingPeriod = this.utilsService.buildWorkingPeriod(breaks, workingPeriod, openTime, closeTime);

            if (openTime && closeTime && currentTimeInMinutes >= openMinutes && currentTimeInMinutes <= closeMinutes) {
                const resultText = workingPeriod.map(item => Object.values(item).join('-')).join(', ');
                return {
                    label: `${openNowText}`,
                    time: `${resultText}`,
                    status: ShopStatuses.open
                };
            }
        } else {
            if (openTime && closeTime && currentTimeInMinutes >= openMinutes && currentTimeInMinutes <= closeMinutes) {
                // if the shop is open, otherwise return "currently not available"
                // return example 'Open now! 8:00-18:00'
                return {
                    label: `${openNowText}`,
                    time: `${openTime}-${closeTime}`,
                    status: ShopStatuses.open
                };
            }
        }

        // if atm data is incorrect
        return {
            label: this.translocoService.translate("authorized.currently_not_available"),
            status: ShopStatuses.not_available
        };
    }

    shopsByDistanceAndStatuses(atms: Atm[]): Atm[] {
        return atms
            .map(shop => {
                shop.distance = this.locationService.calculateDistanceToUser([+shop.latitude, +shop.longitude]);
                shop.shopStatus = this.shopAvailability(shop);
                return shop;
            })
            .filter(shop => shop?.shopStatus?.status === ShopStatuses.open);
    }

    sortShopsByDistance<T>(shopsList: Array<T>): void {
        shopsList.sort((next: any, prev: any) => (next.distance > prev.distance) ? 1 : ((prev.distance > next.distance) ? -1 : 0));
    }

    topButtonsData(): SelectShopButton[] {
        return [
            {
                className: "shops-panel-btn",
                title: "authorized.select_shop_list_dont_know",
                mainIcon: this.sanitized.bypassSecurityTrustHtml(shopListNoShopsIcon),
                secondaryIcon: false,
                selected: false
            },
            {
                className: "shops-panel-btn select-atm-btn",
                title: "authorized.select_shop_list_select_on_the_map",
                mainIcon: this.sanitized.bypassSecurityTrustHtml(shopListSelectOnMapIcon),
                secondaryIcon: true,
                selected: false
            }
        ];
    }

    listWithButtons(topButtons, selectedBtn, shopsNearBy) {
        topButtons.forEach(topBtn => {
            topBtn.selected = false;
            if (topBtn.title === selectedBtn.title) {
                selectedBtn.selected = !selectedBtn.selected;
            }
        });

        if (shopsNearBy && shopsNearBy.value) {
            shopsNearBy.value = shopsNearBy.value.map(item => {
                return { ...item, selected: false };
            });
        }
    }
}
