import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { ShortAtmData } from "../models";
import { Location } from "../constants/location";

@Injectable({
    providedIn: "root",
})
export class LocationService {
    private userLocationSubject: BehaviorSubject<google.maps.LatLngLiteral> = new BehaviorSubject({} as google.maps.LatLngLiteral);
    public readonly userLocation$: Observable<google.maps.LatLngLiteral> = this.userLocationSubject.asObservable();

    public locationSupportedSubject: BehaviorSubject<any> = new BehaviorSubject(Location.DEFAULT);
    public readonly locationSupported$: Observable<any> = this.locationSupportedSubject.asObservable();
    hiddenMap: google.maps.Map;
    service: google.maps.places.PlacesService;

    public requestUserLocation = () => {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };

            const success = ({ coords }) => {
                this.locationSupportedSubject.next(Location.ACTIVE);
                const { latitude, longitude } = coords;
                this.userLocationSubject.next({
                    lat: latitude,
                    lng: longitude,
                });
                // this.subscribeToUserLocation();
            };

            const error = (err: any) => {
                this.locationSupportedSubject.next(Location.DISABLED);
                console.warn(`ERROR(${err.code}): ${err.message}`);
            };
            navigator.geolocation.getCurrentPosition(success, error, options);
        } else {
            console.log("No support for geolocation");
        }
    };

    public subscribeToUserLocation = () => {
        let id;

        const options = {
            enableHighAccuracy: true,
            timeout: 0,
            maximumAge: Infinity,
        };

        const success = (pos) => {
            const latitude = Number(pos.coords.latitude.toFixed(4));
            const longitude = Number(pos.coords.longitude.toFixed(4));

            if (
                latitude !== this.userLocationSubject.value.lat ||
                longitude !== this.userLocationSubject.value.lng
            ) {
                this.userLocationSubject.next({
                    lat: latitude,
                    lng: longitude,
                });
            }
        };

        const error = (err) => {
            console.warn("ERROR(" + err.code + "): " + err.message);
            navigator.geolocation.clearWatch(id);
            this.locationSupportedSubject.next(false);
        };

        id = navigator.geolocation.watchPosition(success, error, options);
    };

    createCoordsObjectFromArray = (coords: Array<number>): google.maps.LatLngLiteral => ({
        lat: Number(coords[0]),
        lng: Number(coords[1]),
    });

    public calculateDistanceToUser = (coords: Array<number>): number => {
        const pointACoords = this.userLocationSubject.getValue();
        const pointBCoords = this.createCoordsObjectFromArray(coords);

        const pointA = {
            lat: Number(pointACoords.lat),
            long: Number(pointACoords.lng),
        };
        const pointB = {
            lat: Number(pointBCoords.lat),
            long: Number(pointBCoords.lng),
        };

        const rad = (x: any) => (x * Math.PI) / 180;

        const R = 6378137; // Earth’s mean radius in meter
        const dLat = rad(pointB.lat - pointA.lat);
        const dLong = rad(pointB.long - pointA.long);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(pointA.lat)) *
            Math.cos(rad(pointB.lat)) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        const distanceInMeters = Math.round(distance);
        const distanceInKilometers = +(distanceInMeters / 1000).toFixed();

        return distanceInMeters;
    };

    moveMap(atm: ShortAtmData, map: google.maps.Map): Observable<any> {
        const points = this.createCoordsObjectFromArray([atm.location[1], atm.location[0]]);

        return of(points);
    }

    moveMapToPlace(place: google.maps.places.AutocompletePrediction, map: google.maps.Map) {
        const detailsRequest: google.maps.places.PlaceDetailsRequest = {
            placeId: place.place_id,
            fields: ["place_id", "geometry", "formatted_address", "name", "icon", "photo"]
        };
        this.hiddenMap = new google.maps.Map(document.getElementById("hiddenMap") as HTMLElement);
        this.service = new google.maps.places.PlacesService(this.hiddenMap);

        this.service.getDetails(detailsRequest, (placeResult: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
            const points = this.createCoordsObjectFromArray([placeResult.geometry.location.lat(), placeResult.geometry.location.lng()]);
            map.panTo(points);
        });

        return of("success").pipe(delay(0));
    }
}
