/* Guard only for DEV and Test environment */
import { inject } from "@angular/core";
import { Router, UrlTree } from "@angular/router";

import { AuthenticationService } from "../services";
import { environment } from "../../environments/environment";

export const loginGuard = (): UrlTree | boolean => {
    const authenticationService = inject(AuthenticationService);
    const router = inject(Router);

    if (environment.name === "DEV" || environment.name === "TEST") {
        if (authenticationService.getAccessToken()) {
            return router.createUrlTree(["/dashboard"]);
        }

        return true;
    } else {
        return router.createUrlTree(["/dashboard"]);
    }
}
