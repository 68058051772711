import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LandscapeModeComponent } from "./landscape-mode.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [LandscapeModeComponent],
    exports: [LandscapeModeComponent],
})
export class LandscapeModeModule {
}
