import { Injectable } from "@angular/core";

import { fromEvent } from "rxjs";
import { pluck } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class ScreenRotateService {
    window$ = fromEvent(window, "orientationchange");
    resize$ = this.window$.pipe(pluck("target"));
}
