import { inject, Pipe, PipeTransform } from "@angular/core";

import { UtilsService } from "../services/utils.service";

@Pipe({
  name: "cardWorkingHours"
})
export class CardWorkingHoursPipe implements PipeTransform {
  private utilsService = inject(UtilsService);

  transform(_, { weekday, openingHours }): string {
    const { open: openTime, close: closeTime, breaks } = openingHours[weekday];
    let workingPeriod = [];
    if (!!breaks && breaks.length) {
      // if atm has breaks
      workingPeriod = this.utilsService.buildWorkingPeriod(breaks, workingPeriod, openTime, closeTime);

      const resultText = workingPeriod.map(item => Object.values(item).join('-')).join(', ');
      return `${resultText}`
    }

    return `${openTime}-${closeTime}`;
  }
}
