import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "dateFormatting"
})
export class FormatDatePipe implements PipeTransform {
    transform(value: string, monthsList: string[]): { month: string, year: string } {
        const year = value.split("-")[0];
        const monthIndex = value.split("-")[1];
        const monthByName = monthsList[monthIndex].substring(0, 3).toLowerCase(); // "month_jan": "January",

        return {
            month: `authorized.month_${monthByName}`,
            year
        };
    }
}
