import { HttpClient } from "@angular/common/http";
import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, TRANSLOCO_MISSING_HANDLER, TranslocoConfig, translocoConfig, TranslocoLoader, TranslocoModule, TranslocoMissingHandler } from "@ngneat/transloco";
import { Injectable, NgModule } from "@angular/core";

import { environment } from "../../environments/environment";
import { DE, DEFAULT_LANG, EN, ES, ES_MX, FR, IT } from "src/app/constants/available-langs";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {
    }

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}

export class CustomHandler implements TranslocoMissingHandler {
    handle(key: string, config: TranslocoConfig) {
        return '';
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [EN, DE, FR, IT, ES, ES_MX],
                defaultLang: DEFAULT_LANG,
                fallbackLang: DEFAULT_LANG,
                prodMode: environment.production,
            })
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
        { provide: TRANSLOCO_MISSING_HANDLER, useClass: CustomHandler }
    ]
})
export class TranslocoRootModule {
}
