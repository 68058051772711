import { Router, UrlTree } from "@angular/router";
import { inject } from "@angular/core";

import { environment } from "../../environments/environment";

export const codeGuard = (): UrlTree | boolean => {
    const router = inject(Router);

    if (environment.name === "PROD") {
        return router.createUrlTree(["/dashboard"]);
    }

    return true;
}
