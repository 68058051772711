/**
 * Created by Arindam Bajpayee on 28-04-2017.
 */
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";

import { Response, User } from "../models";
import { UserLanguagesService } from "./user-languages.service";
import { ApplicationConfiguration } from "../interfaces/application-configuration.interface";
import { LOCAL_STORAGE_KEYS } from "src/app/constants/localStorageKeys";
import { SESSION_STORAGE_KEYS } from "src/app/constants/sessionStorageKeys";

interface IAuthHeaders {
    headers: { [name: string]: string | string[] } | HttpHeaders;
}

const TWINT_INFO = {
    DEV: {
        cId: "425f49f0-5d75-11eb-8d13-a3db50eb10c7",
        cS: "9487116dfc18717e0ef6749fbaa5d0bd3d25bd99779f49114e9289b5c899b3bf"
    },
    TEST: {
        cId: "3f2fe510-606c-11eb-a67c-99a9730b2308",
        cS: "7eaa57627714eb9e65703ff78a4cbe97073f434e484fccdc7530c654f54991c8",
    },
};

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    configuration$ = new BehaviorSubject<ApplicationConfiguration>(null);
    private user$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

    private DEFAULT_COUNTRY_CODE = "CH";
    private SELECTED_COUNTRY_KEY = "SELECTED_COUNTRY_KEY";
    private SELECTED_COUNTRY_CODE_KEY = "SELECTED_COUNTRY_CODE_KEY";

    constructor(
        private http: HttpClient,
        private router: Router,
        private languagesService: UserLanguagesService
    ) { }

    clear(): void {
        sessionStorage.clear(); // keep this line to be sure that's all items is removed
    }

    getAccessToken(): string {
        return sessionStorage.getItem(SESSION_STORAGE_KEYS.accessToken);
    }

    getAppConfiguration(): ApplicationConfiguration {
        return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.configuration));
    }

    generateConfiguration(clientId: string, codeChallenge: string, os: string, scheme: string, signature: string, token: string): Observable<any> {
        const locale = this.languagesService.getUserLanguageWithLocal();
        // Check if referrer value is stored in the localStorage
        const referrer = localStorage.getItem(LOCAL_STORAGE_KEYS.referrer) ?? "";

        return this.http.post<Response>(
            `${environment.baseurl.api}/v7/app`,
            { client_id: clientId, code_challenge: codeChallenge, os, referrer, scheme, signature, token },
            {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    "Accept-Language": locale
                }),
                observe: "response",
                responseType: "json"
            })
    }

    getCurrentUser(): any {
        return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.currentUser));
    }

    getHeaders(): { headers: HttpHeaders } {
        return {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "accept-language": this.languagesService.getUserLanguageWithLocal(),
                Authorization: `Bearer ${this.getAccessToken()}`
            })
        };
    }

    getToken(clientId: string, code: string, codeVerifier: string): Observable<any> {
        return this.http.post<Response>(`${environment.baseurl.api}/v7/oauth2/token`,
            { client_id: clientId, code, code_verifier: codeVerifier },
            { observe: "response", responseType: "json" });
    }

    getUserInfoPayment(): Observable<Response> {
        return this.http.get<Response>(`${environment.baseurl.api}/v7/user`);
    }

    setConfiguration(config: ApplicationConfiguration): void {
        this.configuration$.next(config);
    }

    setUser(user: User) {
        this.user$.next(user);
    }

    getUser(): User {
        return this.user$.getValue();
    }

    login({ pairingUuid, customerRelationUuid }): Observable<{ checkInUrl: string }> {
        // working only for DEV and TEST environment
        const cId = TWINT_INFO[environment.name].cId;
        const cS = TWINT_INFO[environment.name].cS;

        const basicToken = "Basic " + window.btoa(`${cId}:${cS}`);
        return this.http.post<{ checkInUrl: string }>(`${environment.baseurl.api}/v2/twint/terminals/sonect/checkin`, {
            pairingUuid,
            customerRelationUuid
        }, {
            headers: new HttpHeaders({
                Authorization: basicToken,
            })
        });
    }

    /* PRIVATE */
}
