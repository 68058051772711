import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BehaviorSubject, EMPTY, Observable, Subject } from "rxjs";
import { Response, ShopsInRangeOpts } from "../models";
import { AtmCategory } from "../constants/atm-category";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class ShopSearchService {
    shopList: Subject<any[]> = new Subject<any[]>();
    private selectedAtmName$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    constructor(private httpClient: HttpClient) {
    }

    getShopsByKey(key): Observable<Response> {
        return this.httpClient.get<Response>(environment.baseurl.api + `/atm/search?term=${key}`);
    }

    getShopsById(ids: string[]): Observable<Response> {
        return this.httpClient.post<Response>(`${environment.baseurl.api}/atm`, { ids });
        // for UA testing domain CH
        // return this.httpClient.post<Response>(`${environment.baseurl.api}/atm`, { ids, domain: "CH" });
    }

    getShopsInRange({ range, lat, lng, amount, currency }: ShopsInRangeOpts): Observable<Response> {
         // for UA testing lng / ltd
         // const queryParams = !amount ? `range=${range}&latitude=${47.362595645851506}&longitude=${8.549202569935364}&domain=CH` : `range=${range}&latitude=${47.362595645851506}&longitude=${8.549202569935364}&amount=${amount}&currency=${currency}&domain=CH`;
         const queryParams = !amount ?
            `range=${range}&latitude=${lat}&longitude=${lng}&domain=CH` :
            `range=${range}&latitude=${lat}&longitude=${lng}&amount=${amount}&currency=${currency}&domain=CH`;
         return this.httpClient.get<Response>(`${environment.baseurl.api}/atm/inRange?${queryParams}`);
    }

    getShopsInView(mapBounds, mapZoom): Observable<Response> {
        const northEastCornerCoords = mapBounds.getNorthEast();
        const southWestCornerCoords = mapBounds.getSouthWest();
        const tr = [ northEastCornerCoords.lng(), northEastCornerCoords.lat() ];
        const bl = [ southWestCornerCoords.lng(), southWestCornerCoords.lat() ];
        if (tr.includes(NaN) || bl.includes(NaN)) {
            return EMPTY;
        }

        return this.httpClient.get<Response>(environment.baseurl.api + `/atm/inView?clustering=false&tr=${tr}&bl=${bl}&zoom=${mapZoom}&domain=CH`);
    }

    getShopsGroupDetails(ids: number[]) {
        return this.httpClient.post<any>(`${environment.baseurl.api}/atm/group`, { ids });
        // for UA testing domain CH
        // return this.httpClient.post<any>(`${environment.baseurl.api}/atm/group`, { ids, domain: "CH" });
    }

    setShopList(shops): void {
        this.shopList.next(shops);
    }

    setSelectedAtmName(name: string): void {
        this.selectedAtmName$.next(name);
    }

    getSelectedAtmName(): string {
        return this.selectedAtmName$.getValue();
    }

    getSearchIcon(name: string, category: string, parent: string): string {
        let path: string;

        if (!parent) {
            switch (category) {
                case AtmCategory.bakery:
                    path = "./assets/img/illustrations/categories/bakery.png";
                    break;
                case AtmCategory.coffeeShop:
                    path = "./assets/img/illustrations/categories/coffeeShop.png";
                    break;
                case AtmCategory.events:
                    path = "./assets/img/illustrations/categories/events.png";
                    break;
                case AtmCategory.generic:
                    path = "./assets/img/illustrations/categories/general.png";
                    break;
                case AtmCategory.pharmacy:
                    path = "./assets/img/illustrations/categories/pharmacy.png";
                    break;
                case AtmCategory.restaurant:
                    path = "./assets/img/illustrations/categories/restaurant.png";
                    break;
                case AtmCategory.kiosk:
                    path = "./assets/img/illustrations/categories/icon_kiosk.png";
                    break;
                case AtmCategory.shop:
                    path = "./assets/img/illustrations/categories/icon_shop.png";
                    break;
                case AtmCategory.carWash:
                    path = "./assets/img/illustrations/categories/general.png";
                    break;
                case AtmCategory.gasStation:
                    path = "./assets/img/illustrations/categories/general.png";
                    break;
                case AtmCategory.taxi:
                    path = "./assets/img/illustrations/categories/general.png";
                    break;
                case AtmCategory.foodTruck:
                    path = "./assets/img/illustrations/categories/general.png";
                    break;
                case AtmCategory.onlineDelivery:
                    path = "./assets/img/illustrations/categories/general.png";
                    break;
                case AtmCategory.bar:
                    path = "./assets/img/illustrations/categories/events.png";
                    break;
                case AtmCategory.hotel:
                    path = "./assets/img/illustrations/categories/events.png";
                    break;
                case AtmCategory.supermarket:
                    path = "./assets/img/illustrations/categories/icon_shop.png";
                    break;

                default:
                    path = "./assets/img/illustrations/categories/general.png";
                    break;
            }
        }

        if (parent) {
            path = this.getIncludesIcons(name);
        }

        if (!path) {
            path = "./assets/img/icons/item-image.svg";
        }

        return path;
    }

    private getIncludesIcons(name: string): string {
        let path = "";
        switch (true) {
            case name.toLowerCase().trim().includes("volg"):
                path = "./assets/img/illustrations/categories/volg.svg";
                break;
            case name.toLowerCase().trim().includes("valora"):
                path = "./assets/img/illustrations/categories/valora.svg";
                break;
            case name.toLowerCase().trim().includes("kiosk"):
                path = "./assets/img/illustrations/categories/kkiosk.svg";
                break;
            case name.toLowerCase().trim().includes("prima"):
                path = "./assets/img/illustrations/categories/prima.svg";
                break;
            case name.toLowerCase().trim().includes("topshop"):
                path = "./assets/img/illustrations/categories/topshop.svg";
                break;
            case name.toLowerCase().trim().includes("avec"):
                path = "./assets/img/illustrations/categories/avec.svg";
                break;
            case name.toLowerCase().trim().includes("press & books"):
                path = "./assets/img/illustrations/categories/press_book.svg";
                break;
        }

        return path;
    }
}
