<div class="landscape">
    <div class="landscape-body">
        <div class="landscape-body-inner">
            <svg class="svg-icon" data-name="portrait_landscape_mode" id="portrait_landscape_mode" viewBox="0 0 155.1 128.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <style>
                        .cls-1 {
                            fill: var(--sonectTitleColor);
                        }

                        .cls-2 {
                            fill: var(--sonectTitleColor);
                            stroke: var(--sonectTitleColor);
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 5px;
                        }
                    </style>
                </defs>
                <path d="M8.84,58.59q0-18.5,0-37c0-8.24,4.28-12.51,12.49-12.52q20.49,0,41,0c8.21,0,12.5,4.31,12.52,12.51,0,10.83,0,21.67,0,32.5,0,3.49,0,3.51,3.41,3.51q29.16,0,58.24,0c9,0,13.25,4.26,13.26,13.34q0,20,0,40c0,8.1-4.59,12.67-12.74,12.67-24.41,0-48.83-.12-73.24.09-8.06.07-13.64-4.93-13-12.82.18-2.29-.91-2.82-3-2.8-8.92.07-17.83,0-26.75,0-7.86,0-12.18-4.35-12.2-12.25C8.82,83.43,8.84,71,8.84,58.59Zm91.58,3.33q-18.63,0-37.25,0c-5.69,0-8.17,2.51-8.17,8.25q0,20.38,0,40.76c0,5.75,2.42,8.16,8.23,8.17q37,0,74,0c5.48,0,8-2.44,8-7.86q.06-20.76,0-41.51c0-5.29-2.58-7.8-7.82-7.81ZM13.08,58.5c0,12.5,0,25,0,37.5,0,5,2.86,7.85,7.93,7.86,8.83,0,17.66,0,26.49,0,3.23,0,3.24,0,3.24-3.38,0-10.59,0-21.17,0-31.75.06-6.45,4.33-10.78,10.77-11.1a57.2,57.2,0,0,1,6,0c2.2.13,2.91-.82,2.9-2.94-.07-11.17,0-22.33,0-33.5,0-5-2.55-7.56-7.56-7.58q-21-.06-42,0c-5.22,0-7.77,2.67-7.78,7.92Q13.06,40,13.08,58.5Z"/>
                <path class="cls-1" d="M100.42,61.93h37c5.24,0,7.81,2.52,7.82,7.81q.07,20.74,0,41.51c0,5.42-2.54,7.85-8,7.86q-37,0-74,0c-5.81,0-8.23-2.42-8.23-8.17q0-20.37,0-40.76c0-5.74,2.48-8.24,8.17-8.25Q81.8,61.9,100.42,61.93Z"/>
                <path class="cls-1" d="M13.08,58.5q0-18.49,0-37c0-5.25,2.56-7.9,7.78-7.92q21-.08,42,0c5,0,7.54,2.58,7.56,7.58,0,11.17,0,22.33,0,33.5,0,2.12-.7,3.07-2.9,2.94a57.2,57.2,0,0,0-6,0c-6.44.32-10.71,4.65-10.77,11.1-.08,10.58,0,21.16,0,31.75,0,3.37,0,3.38-3.24,3.38-8.83,0-17.66,0-26.49,0-5.07,0-7.92-2.81-7.93-7.86C13.06,83.5,13.08,71,13.08,58.5Z"/>
                <path class="cls-2" d="M114.31,46.64S113.67,22,86.38,22l9.2-8"/>
                <line class="cls-2" x1="94.13" x2="86.38" y1="31.88" y2="21.95"/>
            </svg>
        </div>
        <h4 class="landscape-body__title">Please rotate back to portrait mode.</h4>
    </div>
</div>
