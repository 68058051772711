import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { map } from "rxjs/operators";
import { AuthenticationService } from "../services";
import { Response } from "../models";

export const promocodeGuard = () => {
    const authenticationService = inject(AuthenticationService);
    const router = inject(Router);

    return authenticationService.getUserInfoPayment()
        .pipe(
            map(({ payload }: Response) => {
                if (!payload.promotion.eligible && Object.keys(payload.promotion).length === 1) {
                    return router.createUrlTree(['/dashboard']);
                }
                return true;
            })
        )
}
